import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-scroll'





const NavBar = () => {

  return (
    <Navbar collapseOnSelect bg='light' expand='md' fixed='top'>
      <Container>
        <Navbar.Toggle aria-controls='crate-nav' />
        <Navbar.Collapse id='site-nav' className='justify-content-end'>
          <Nav>
            <Link className='nav-link' activeClass='active' to="about" spy={true} smooth={true} offset={-100} duration={600}>about</Link>
            <Link className='nav-link' activeClass='active' to="skills" spy={true} smooth={true} offset={-100} duration={600}>skills</Link>
            <Link className='nav-link' activeClass='active' to="hero" spy={true} smooth={true} offset={-106} duration={600}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="35px" height="35px" viewBox="0 0 2000.000000 2250.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,2250.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M9715 21389 c-324 -17 -730 -59 -1055 -109 -2123 -330 -4193 -1390
-5740 -2939 -220 -221 -396 -412 -584 -636 -147 -174 -155 -182 -122 -120 35
66 144 194 343 404 159 168 242 270 262 324 6 15 9 27 7 27 -9 0 -287 -314
-421 -475 -811 -973 -1376 -1943 -1787 -3069 -171 -469 -373 -1220 -383 -1425
-5 -95 -32 -250 -95 -551 -67 -318 -81 -399 -85 -510 -2 -52 -15 -169 -30
-260 -28 -177 -26 -265 4 -156 18 68 40 86 42 34 5 -123 -29 -502 -51 -578
-14 -46 -14 -444 0 -585 14 -145 36 -264 62 -332 18 -50 21 -80 22 -297 2
-164 8 -279 19 -359 69 -473 208 -1023 449 -1762 86 -264 92 -278 103 -251 15
37 21 28 51 -87 16 -61 60 -165 129 -307 57 -118 116 -253 130 -300 30 -97 28
-91 148 -300 50 -85 138 -249 198 -365 71 -140 128 -236 168 -287 34 -43 100
-146 147 -230 201 -358 440 -683 909 -1233 264 -311 403 -463 615 -675 438
-438 913 -829 1431 -1177 1535 -1032 3232 -1598 5079 -1694 282 -14 961 -6
1230 15 736 59 1386 175 2060 367 557 159 1329 464 1514 598 37 28 89 58 115
67 371 135 644 289 1211 684 357 249 418 291 510 349 47 29 114 82 150 116 36
34 151 138 255 230 305 270 432 403 474 496 10 21 60 76 121 131 268 243 529
566 832 1033 40 60 98 132 148 180 45 44 91 98 102 120 12 22 74 115 140 207
65 92 149 215 187 275 71 113 146 253 135 253 -4 0 -22 -11 -40 -25 -40 -31
-52 -31 -59 -2 -4 16 25 85 99 237 527 1086 868 2352 946 3518 13 192 13 658
0 817 -5 66 -17 185 -27 265 l-18 145 -6 -315 c-12 -573 -53 -1031 -140 -1560
-182 -1107 -560 -2174 -1026 -2894 -35 -54 -82 -135 -105 -180 -231 -460 -625
-999 -1102 -1509 -168 -179 -486 -491 -636 -624 -63 -56 -143 -134 -178 -172
-94 -106 -322 -296 -585 -490 -584 -430 -1665 -1018 -2447 -1331 -774 -310
-1602 -502 -2268 -525 l-137 -5 185 24 c1885 241 3619 996 4983 2169 251 216
626 595 808 817 l53 65 -84 -80 c-47 -43 -173 -163 -280 -266 -708 -678 -1158
-1010 -1905 -1407 -108 -57 -186 -106 -215 -134 -135 -130 -644 -363 -1120
-512 -62 -19 -109 -31 -104 -27 5 5 128 59 274 121 320 136 558 251 663 319
42 28 77 53 77 56 0 2 -35 -12 -77 -31 -1152 -531 -2219 -841 -3263 -948 -498
-52 -1029 -52 -1510 0 -80 8 -149 16 -155 18 -5 2 251 6 570 9 788 8 1142 31
1615 103 129 20 191 35 285 70 67 24 173 55 236 68 883 187 1900 576 2707
1035 586 334 1180 766 1557 1134 l90 88 -85 -72 c-594 -503 -1248 -953 -1750
-1205 -146 -73 -340 -158 -340 -149 0 2 73 45 163 96 605 345 1076 655 1432
943 114 93 287 256 348 330 l42 51 -55 -48 c-221 -194 -296 -255 -453 -373
-417 -313 -899 -604 -1467 -888 -357 -178 -780 -363 -894 -392 -27 -6 -29 -5
-23 16 4 17 2 21 -11 16 -9 -3 -69 -21 -132 -40 -94 -29 -120 -41 -142 -67
-31 -36 -79 -51 -548 -169 -884 -222 -1570 -320 -2222 -320 -309 1 -423 9
-578 45 -140 32 -390 54 -605 55 -506 1 -1404 245 -2281 618 -1085 462 -1158
502 -1780 987 -78 61 -231 179 -341 263 -882 673 -1584 1398 -2090 2157 -1168
1752 -1711 3918 -1507 6005 161 1643 791 3268 1769 4565 79 105 192 248 252
319 59 71 133 166 163 211 70 103 204 259 208 242 11 -38 11 -39 43 -20 17 10
55 44 85 76 52 56 69 97 40 97 -8 0 -18 4 -21 10 -14 22 352 389 627 627 693
601 1576 1135 2496 1510 956 388 1972 615 2917 650 l179 6 61 38 c88 55 175
81 269 81 43 1 81 -4 85 -9 3 -6 81 -10 181 -11 115 0 195 -6 233 -15 60 -15
68 -13 40 10 -16 13 -12 14 37 8 30 -4 97 -10 147 -14 99 -8 134 -20 62 -22
-106 -3 -103 -16 6 -23 229 -15 350 -30 695 -86 382 -63 541 -85 561 -78 7 2
-48 17 -122 32 -213 45 -270 58 -266 63 2 2 61 -8 129 -22 69 -14 144 -25 167
-25 37 0 41 3 41 24 0 13 5 26 12 28 12 4 219 -48 328 -83 102 -33 521 -182
518 -184 -2 -2 -45 8 -96 20 -158 39 -184 38 -62 -3 131 -45 353 -133 347
-138 -6 -7 -149 36 -387 116 -220 74 -398 125 -406 118 -2 -2 63 -26 144 -52
620 -204 1350 -492 1852 -733 85 -40 150 -70 145 -64 -12 11 -261 136 -441
221 -70 33 -125 63 -122 66 3 2 55 -11 115 -31 61 -19 122 -35 137 -35 31 0
108 -38 235 -115 123 -74 221 -123 221 -110 0 8 -230 140 -380 219 -80 42
-142 76 -137 76 12 0 277 -135 445 -227 1273 -698 2377 -1693 3164 -2853 173
-254 278 -432 401 -675 77 -153 131 -247 161 -280 86 -98 65 -22 -37 133 -95
143 -101 157 -82 185 19 30 4 86 -35 130 l-29 32 -1 -27 c0 -60 -24 -34 -120
137 -325 576 -711 1084 -1230 1620 -116 120 -191 203 -284 315 -50 59 113 -93
259 -241 350 -358 727 -810 1055 -1269 147 -205 144 -201 134 -177 -3 9 -2 17
2 17 18 -1 254 -435 405 -745 287 -589 514 -1191 740 -1962 49 -168 69 -216
69 -163 0 38 20 20 34 -32 46 -158 131 -607 176 -918 54 -380 77 -668 87
-1090 8 -327 15 -318 32 39 52 1082 -282 2590 -859 3876 -106 236 -137 291
-187 332 -51 42 -78 89 -104 186 -39 140 -185 392 -444 767 -380 549 -735 971
-1211 1440 -428 422 -817 745 -1314 1089 -152 106 -164 115 -135 106 11 -4 5
2 -13 14 -18 12 -38 19 -43 15 -5 -3 -8 0 -7 8 2 7 -5 12 -14 12 -10 -1 -16 2
-13 6 13 20 -478 301 -845 484 -1128 561 -2274 866 -3640 968 -234 17 -1075
17 -1315 0 -232 -17 -530 -47 -720 -73 -358 -48 -780 -126 -837 -155 -51 -25
-307 -178 -304 -181 2 -2 59 13 126 32 68 20 245 60 394 91 149 30 273 57 275
60 15 15 -33 23 -104 18 -58 -4 -76 -3 -65 5 8 6 31 11 50 11 19 0 85 7 145
15 123 16 205 19 205 7 0 -5 -22 -19 -50 -32 -27 -13 -49 -25 -47 -26 1 -1 34
4 72 12 105 22 357 64 380 63 11 0 1 -6 -23 -14 -23 -7 -53 -20 -65 -28 -13
-8 -71 -20 -132 -26 -406 -43 -913 -149 -1345 -280 -1175 -356 -2379 -1015
-3347 -1832 -100 -85 -288 -243 -417 -351 -325 -274 -457 -397 -599 -561 -275
-317 -457 -562 -807 -1087 -161 -242 -318 -470 -332 -485 -16 -16 59 130 125
245 234 406 604 908 961 1305 319 353 826 831 1326 1249 319 267 356 296 382
296 47 0 153 67 472 298 109 79 208 145 220 147 12 1 46 14 76 29 30 14 63 26
73 26 9 0 68 29 130 64 370 210 705 354 1417 610 129 46 246 89 260 95 l25 12
-25 -6 c-14 -3 -54 -12 -89 -21 -96 -23 -106 -12 -16 19 85 29 145 60 145 76
0 21 -324 -89 -755 -256 -111 -43 -137 -69 -60 -60 61 6 49 -4 -55 -48 -47
-20 -79 -31 -72 -24 20 21 15 38 -10 31 -14 -3 -19 -1 -14 6 13 22 -411 -172
-739 -339 -217 -109 -321 -166 -535 -287 -76 -43 -31 -9 85 65 278 177 475
287 815 457 1093 544 2273 860 3505 937 247 16 952 16 1175 0 975 -66 1798
-229 2685 -531 478 -163 726 -271 1535 -668 358 -176 828 -485 1225 -806 273
-221 521 -444 845 -762 201 -197 258 -244 293 -244 17 0 152 -123 168 -151 9
-18 9 -18 -26 -2 -19 9 -38 14 -41 10 -8 -8 71 -112 99 -132 18 -12 22 -12 34
3 12 15 18 12 64 -38 28 -30 60 -68 72 -85 20 -28 19 -28 -23 8 -95 81 -56 29
91 -120 277 -282 512 -590 771 -1013 210 -342 488 -878 688 -1327 52 -116 96
-210 98 -209 12 13 -188 517 -274 687 -19 39 -33 73 -31 75 2 2 41 -73 87
-168 283 -590 437 -1021 637 -1777 23 -88 43 -146 52 -149 12 -4 13 11 8 89
-6 81 -4 91 7 76 8 -10 17 -44 20 -75 39 -335 56 -432 92 -511 13 -30 26 -52
28 -50 3 2 -11 90 -29 194 -61 344 -135 633 -193 755 -14 30 -25 65 -24 76 10
73 -142 494 -216 602 -10 15 -24 59 -31 97 -19 101 -99 337 -170 502 -65 153
-232 491 -326 663 -32 58 -100 182 -150 276 -236 437 -438 756 -711 1120 -798
1069 -1846 1979 -3044 2646 -150 84 -528 274 -660 333 -47 21 -95 49 -106 64
-71 86 -330 202 -819 366 -888 298 -1776 464 -2745 515 -222 11 -831 11 -1055
-1z m840 -539 c63 -4 -69 -7 -353 -8 -249 -1 -496 -7 -550 -13 -98 -10 -209
-34 -227 -49 -5 -4 -64 -10 -130 -14 l-120 -5 55 15 c30 8 73 20 95 26 37 10
38 12 15 18 -21 5 53 12 370 33 93 7 749 4 845 -3z m2152 -16 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m40 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m141 -34 c124 -31 265 -75 291 -90 12 -7 9 -9 -14
-10 -26 0 -426 129 -402 130 5 0 61 -13 125 -30z m-2918 10 c-123 -9 -476 -19
-440 -13 54 8 292 20 415 20 l130 0 -105 -7z m250 -189 c-8 -5 -75 -14 -148
-21 -105 -9 -147 -9 -203 1 -38 7 -72 16 -75 21 -3 4 95 8 218 8 127 0 217 -4
208 -9z m640 -21 c305 -34 599 -65 703 -74 83 -8 82 7 -1 26 l-57 13 43 3 c38
3 273 -35 362 -58 71 -19 -347 -1 -590 26 -184 20 -314 27 -525 30 -154 3
-289 7 -300 9 -11 2 -25 16 -32 30 l-11 25 66 0 c37 0 191 -14 342 -30z m1975
-140 c41 -16 45 -19 23 -20 -33 0 -34 -6 -10 -33 17 -19 16 -20 -23 -13 -73
13 -113 26 -125 41 -21 26 2 40 41 26 44 -15 58 -13 35 4 -28 21 -2 19 59 -5z
m-898 -46 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1798 -431
c77 -31 169 -71 205 -90 83 -44 75 -41 -164 56 -109 45 -202 79 -205 75 -3 -3
2 -10 12 -15 9 -6 29 -21 45 -34 23 -22 15 -20 -58 14 -47 21 -131 55 -187 76
-56 21 -100 42 -97 46 7 12 -38 32 -136 62 -47 14 -94 33 -105 42 -25 19 501
-158 690 -232z m230 -133 c99 -43 203 -87 231 -98 28 -10 62 -27 75 -36 l23
-16 -24 0 c-23 0 -280 83 -310 101 -28 16 21 10 80 -11 99 -34 63 -14 -97 54
-87 37 -169 74 -183 82 l-25 15 25 -7 c14 -3 106 -41 205 -84z m1482 -592 c76
-53 153 -130 153 -154 0 -16 -203 134 -237 174 -51 60 -23 54 84 -20z m670
-509 c74 -64 235 -219 227 -219 -19 0 -279 221 -288 245 -9 26 11 17 61 -26z
m-15917 -9183 c11 -60 20 -126 19 -145 0 -34 -1 -35 -10 -11 -6 14 -19 84 -30
155 -33 225 -21 225 21 1z m10907 -7982 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z"/>
                <path d="M8280 15659 c-35 -14 -78 -57 -103 -104 -20 -37 -22 -57 -25 -267 -4
-264 4 -314 63 -371 53 -52 85 -57 351 -57 210 0 244 3 279 19 51 23 101 79
114 128 15 52 14 465 0 514 -16 52 -64 108 -113 130 -37 17 -69 19 -291 18
-141 0 -261 -5 -275 -10z"/>
                <path d="M14069 15647 c-29 -16 -54 -40 -74 -72 l-30 -48 -5 -1193 -5 -1192
-80 91 c-215 245 -548 471 -834 566 -208 69 -435 101 -726 101 -387 0 -661
-55 -935 -186 -381 -183 -668 -475 -870 -888 -87 -179 -132 -300 -181 -493
-92 -361 -123 -633 -123 -1083 0 -450 31 -722 123 -1083 103 -403 295 -764
538 -1012 258 -265 575 -437 937 -510 193 -39 322 -48 584 -42 441 10 714 81
1017 266 179 109 401 301 507 438 l43 56 5 -259 c5 -240 7 -262 26 -299 25
-46 45 -65 94 -91 29 -16 58 -19 180 -19 131 0 149 2 191 23 28 14 58 39 75
64 l29 41 3 3336 c2 2346 0 3347 -8 3373 -14 48 -53 94 -101 118 -33 17 -59
20 -187 20 -137 0 -152 -2 -193 -23z m-1354 -2341 c218 -42 412 -124 565 -240
118 -88 284 -263 363 -382 129 -194 231 -447 281 -694 38 -188 49 -410 43
-835 -6 -412 -16 -514 -72 -729 -120 -472 -352 -819 -695 -1044 -223 -147
-478 -212 -835 -212 -485 0 -804 121 -1077 409 -192 202 -301 419 -392 780
-78 309 -108 702 -88 1129 13 269 36 445 86 647 89 359 201 584 394 786 227
239 446 343 847 403 71 10 502 -3 580 -18z"/>
                <path d="M4595 13888 c-381 -43 -690 -171 -962 -400 -227 -190 -394 -470 -460
-770 -27 -124 -24 -397 5 -535 54 -250 140 -421 283 -564 237 -238 595 -405
1166 -544 664 -162 886 -224 1092 -305 273 -107 446 -232 518 -375 53 -103 65
-154 70 -291 6 -151 -5 -235 -46 -339 -122 -314 -493 -526 -1026 -585 -118
-13 -435 -13 -566 0 -145 15 -316 55 -436 101 -234 91 -401 229 -665 549 -32
38 -72 74 -98 88 -41 21 -56 23 -180 20 -108 -2 -143 -7 -173 -22 -94 -47
-129 -116 -109 -214 38 -179 225 -440 447 -622 282 -231 664 -394 1070 -456
99 -16 177 -19 445 -19 286 1 342 4 468 23 652 102 1125 407 1347 867 89 186
125 350 125 574 0 542 -226 898 -731 1152 -256 128 -437 186 -1039 334 -595
145 -954 278 -1146 424 -175 132 -253 309 -240 546 6 132 24 203 76 310 126
256 366 411 740 477 121 21 618 17 745 -6 355 -65 571 -207 783 -514 114 -166
142 -181 328 -182 93 0 142 4 167 15 90 37 135 114 123 207 -23 171 -181 416
-383 594 -282 248 -665 411 -1081 459 -140 17 -523 18 -657 3z"/>
                <path d="M8415 13799 c-51 -12 -112 -61 -134 -108 -15 -33 -16 -274 -21 -2766
-5 -2550 -7 -2737 -23 -2833 -45 -261 -91 -367 -211 -488 -114 -115 -204 -157
-401 -189 -49 -8 -136 -15 -192 -15 -145 0 -195 -13 -248 -64 -53 -51 -65 -90
-65 -221 0 -160 30 -229 118 -268 42 -18 60 -19 241 -14 383 10 642 84 856
244 277 207 416 480 498 976 l22 132 3 2725 c2 2469 1 2729 -13 2767 -23 59
-81 108 -144 122 -61 13 -228 13 -286 0z"/>
                <path d="M16238 9601 c-83 -26 -133 -87 -148 -177 -13 -78 -13 -487 0 -555 13
-70 50 -123 103 -151 41 -22 49 -23 342 -23 295 0 301 0 348 24 54 26 90 68
107 126 8 28 10 128 8 325 l-3 285 -28 47 c-18 31 -44 57 -75 75 l-47 28 -290
2 c-159 1 -302 -2 -317 -6z"/>
              </g>
            </svg></Link>
            <Link className='nav-link' activeClass='active' to="projects" spy={true} smooth={true} offset={-100} duration={600}>projects</Link>
            <Link className='nav-link' activeClass='active' to="wave-point" spy={true} smooth={true} offset={-390 + 45} duration={600}>contact</Link>
            {/* <Link className='nav-link' activeClass="active" to="experience" spy={true} smooth={true} offset={-50} duration={600}>Experience</Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar